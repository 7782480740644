<template>
  <v-card class="my-5">
    <v-toolbar elevation="5" color="green darken-2" dark>
      <v-btn icon @click="back()" class="pl-2">
        <v-icon x-large color="white">mdi-arrow-left-circle</v-icon>
      </v-btn>
      <v-toolbar-title class="row justify-center">
        <v-toolbar-title>Создать новость</v-toolbar-title>
      </v-toolbar-title>
    </v-toolbar>
    <v-row class="mx-3 my-6">
      <v-col cols="12" class="col col-lg-5 col-md-5 col-sm-5">
        <v-text-field dense
                      outlined
                      rounded
                      v-model="item.title"
                      clearable
                      hide-details
                      label="Заголовок" />
      </v-col>
      <v-col cols="12" class="col col-lg-5 col-md-5 col-sm-5">
        <v-text-field dense
                      outlined
                      rounded
                      v-model="item.subtitle"
                      clearable
                      hide-details
                      label="Подзаголовок" />
      </v-col>
      <v-col cols="12" class="col col-lg-2 col-md-2 col-sm-2">
        <VueCtkDateTimePicker
            v-model="item.createDate"
            format="YYYY-MM-DDTHH:mm:ssZ"
            formatted="YYYY-MM-DD HH:mm"
            locale="ru"
            label="Дата создания"
            id="item.createDate"
            color="#3FC44F"
            right
            button-color="#3FC44F" />
      </v-col>
      <v-col cols="12">
        <vue-editor v-model="item.description"></vue-editor>
      </v-col>
      <v-col cols="12">
        <v-btn color="primary" dark @click="create">Создать</v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import {VueEditor} from "vue2-editor";
import {uuid} from "uuidv4";

export default {
  components: {
    VueEditor
  },
  data() {
    return {
      item: {
        id: uuid.Nil,
        title: "",
        subtitle: "",
        description: "",
        createDate: ""
      },
      defaultItem: {
        id: uuid.Nil,
        title: "",
        subtitle: "",
        description: "",
        createDate: ""
      }
    }
  },

  methods: {
    create() {
      this.$store.dispatch("news/create", this.item);
      this.item = this.defaultItem;
      const validate = this.validateFields();
      if (validate) {
        this.$router.push({name: 'news'})
      }
    },
    back() {
      this.$router.push({name: 'news'});
    },
    validateFields() {
      if (
          !this.item.title ||
          !this.item.subtitle ||
          !this.item.description ||
          !this.item.createDate
      ) {
        this.$alert("Пожалуйста заполните все поля");
        return false
      }
      return true
    }
  }
}
</script>
